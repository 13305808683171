import React from "react";
import Header from "./Components/Header";
// import Main from "./Components/Main";
import Footer from "./Components/Footer";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Home from "./Components/Main/Home";
import AboutUs from "./Components/Main/AboutUs";
import Services from "./Components/Main/Services";
import WhyChooseUs from "./Components/Main/WhyChooseUs";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/whyUs" element={<WhyChooseUs />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
