import React, { useState, useEffect, useCallback } from "react";
import "./TypingEffect.css";

const TypingEffect = ({ texts, speed = 100, delay = 1000 }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  const typeText = useCallback(() => {
    if (isTyping) {
      if (charIndex < texts[currentIndex].length) {
        setDisplayedText((prev) => prev + texts[currentIndex][charIndex]);
        setCharIndex((prev) => prev + 1);
      } else {
        setIsTyping(false);
        setTimeout(() => {
          setDisplayedText("");
          setCharIndex(0);
          setIsTyping(true);
          setCurrentIndex((prev) => (prev + 1) % texts.length);
        }, delay);
      }
    }
  }, [charIndex, currentIndex, isTyping, texts, delay]);

  useEffect(() => {
    const timer = setInterval(typeText, speed);
    return () => clearInterval(timer);
  }, [typeText, speed]);

  return (
    <div
      style={{ fontFamily: "poppins", whiteSpace: "nowrap" }}
      className="typingEffectText"
    >
      {displayedText}
      <span className={`cursor ${!isTyping ? "hidden" : ""}`}>&nbsp;</span>
    </div>
  );
};

export default TypingEffect;
