import React from "react";
import "./footer.css";
import logo from "../../Assets/Images/Klug.svg";
import { Link, NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="nav-footer">
        <div className="logo">
          <Link to="/">
            <img alt="logo" src={logo} />
          </Link>
        </div>
        <div className="navLinks">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About Us</NavLink>
          <NavLink to="/services">Our Services</NavLink>
          <NavLink to="/whyUs">Why Us</NavLink>
        </div>
        <div className="copyrightdiv">
          <span className="copyright">Copyright &copy; 2024 | Klug</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
