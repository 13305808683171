import React, { useState, useEffect, useRef } from "react";

const LazyLoadContainer = ({
  src,
  placeholder,
  children,
  className,
  style,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div
      ref={containerRef}
      className={className}
      style={{
        ...style,
        backgroundImage: isLoaded ? `url(${src})` : `url(${placeholder})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity: isLoaded ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
      }}
      {...props}
    >
      <img
        src={src}
        alt=""
        style={{ display: "none" }}
        onLoad={handleImageLoad}
      />
      {isLoaded && children}
    </div>
  );
};

export default LazyLoadContainer;
