import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./SlideAnimation.css";

const SlideAnimation = ({ slides, interval = 3000 }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, interval);

    return () => clearInterval(slideInterval);
  }, [slides.length, interval]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="slide-show">
      <div className="slide-content">{slides[currentSlide]}</div>
      <div className="dots">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentSlide ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

SlideAnimation.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
  interval: PropTypes.number,
};

export default SlideAnimation;
