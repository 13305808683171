// src/Navbar.js
import React, { useState, useEffect } from "react";
import "./navbar.css";
import logo from "../../../Assets/Images/Klug.svg";
import logoMobile from "../../../Assets/Images/KlugMobile.svg";
import { Link, NavLink } from "react-router-dom";
import NavbarModal from "./NavModal/navModal";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWide, setIsWide] = useState(window.innerWidth >= 768);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("nav-open");
    } else {
      document.body.classList.remove("nav-open");
    }
  }, [isOpen]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isModalOpen]);

  const handleScroll = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleNavLinks = () => {
    setIsOpen(!isOpen);
    setIsModalOpen(!isOpen); // Open modal if navbar is toggled
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsOpen(false); // Close navbar when modal is closed
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const pageScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", pageScroll);

    return () => {
      window.removeEventListener("scroll", pageScroll);
    };
  }, []);

  return (
    <>
      <nav>
        <div className={scrolled ? "navbar scrolled" : "navbar"}>
          <div className="logo">
            <Link to="/">
              <img
                className="logo-img"
                alt="logo"
                src={isWide ? logo : logoMobile}
              />
            </Link>
          </div>
          <div
            className={`hamburger ${isOpen ? "open" : ""}`}
            onClick={toggleNavLinks}
          >
            <span></span> <span></span> <span></span>
          </div>
          <div className={`navLinks ${isOpen ? "show" : ""}`}>
            <NavLink to="/">HOME</NavLink>
            <NavLink to="/about">ABOUT US</NavLink>
            <NavLink to="/services">OUR SERVICES</NavLink>
            <NavLink to="/whyUs">WHY US</NavLink>
            <button
              className="primaryBtn"
              onClick={() => {
                handleScroll();
              }}
            >
              CONTACT US
            </button>
          </div>
        </div>
      </nav>
      <NavbarModal
        isOpen={isModalOpen}
        toggleMenu={closeModal}
        handleScroll={handleScroll}
      ></NavbarModal>
    </>
  );
}

export default Navbar;
