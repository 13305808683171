import React from "react";
import "./whychooseus.css";
import whyus1 from "../../../Assets/Images/whyus1.svg";
import whyus2 from "../../../Assets/Images/whyus2.svg";
import whyus3 from "../../../Assets/Images/whyus3.svg";
import whyus4 from "../../../Assets/Images/whyus4.svg";
import whyus5 from "../../../Assets/Images/whyus5.svg";
import whyus6 from "../../../Assets/Images/whyus6.svg";
import ContactFormShort from "../../Shared/ContactFormShort";
import { Link } from "react-router-dom";

function Whychooseus() {
  const handleScroll = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };
  return (
    <section>
      <div className="whychooseus-container">
        <div className="whychooseus-banner">
          <div className="whychooseus-bannerLeft">
            <p>
              <Link to="/">Home</Link> / Why Us
            </p>
            <h1>Why Us</h1>
          </div>
          <div className="whychooseus-bannerRight">
            <p>
              We're not just an IT company. We're a team driven by DevOps
              principles and an Agile mindset. Transparency is key in everything
              we do, no matter the outcome. We see the bigger picture,
              addressing your business needs and future goals. Our approach lets
              you focus on what matters most—growing your business.
            </p>
          </div>
        </div>
        <div className="whychooseus-talktous">
          <div className="gridLeft">
            <h2>
              What makes us{" "}
              <span>
                <br></br>Unique
              </span>
            </h2>
            <button className="primaryBtn" onClick={handleScroll}>
              Talk To Us
            </button>
          </div>
          <div className="gridRight">
            <h5>Agile Expertise, Transparent Process</h5>
            <p>
              With over three decades of experience in the IT industry, we bring
              a deep understanding and extensive expertise to every project. We
              follow the agile model to break down complex problems into small
              user stories, ensuring efficient and effective solutions.
              Transparent and consistent updates always remain our priority,
              ensuring you are always informed about the progress.
            </p>
          </div>
          <div className="whychooseus-btnresponsive">
            <button className="primaryBtn" onClick={handleScroll}>
              Talk To Us
            </button>
          </div>
        </div>
        <div className="whyusServices">
          <div className="whyusServices-text">
            <h1>
              {" "}
              <span>Reliable Solutions </span>
              <br></br>Supported By Core Values{" "}
            </h1>
          </div>
          <div className="servicePoints">
            <div className="whyusServicePoints service1">
              <img alt="Service Image" src={whyus1}></img>
              <h5>Experienced Team</h5>
              <p>
                We have over 30 years of industry experience and fresh minds
                giving out-of-the-box ideas, ensuring expert solutions and
                innovative strategies.
              </p>
            </div>
            <div className="whyusServicePoints service2">
              <img alt="Service Image" src={whyus2}></img>
              <h5>Transparent Approach</h5>
              <p>
                We always maintain clear communication and honesty throughout
                your journey. We do not believe in hidden agendas. We give
                straightforward updates and decisions to keep you informed.
              </p>
            </div>
            <div className="whyusServicePoints service3">
              <img alt="Service Image" src={whyus3}></img>
              <h5>Latest Skills & Knowledge</h5>
              <p>
                We continuously update our skills and knowledge to deliver
                better solutions. Our commitment to learning ensures your
                project benefits from the latest industrial advancements.
              </p>
            </div>
            <div className="whyusServicePoints service4">
              <img alt="Service Image" src={whyus4}></img>
              <h5>Maintenance and Support</h5>
              <p>
                We provide comprehensive maintenance services to keep your
                solutions running smoothly and address any issues promptly. We
                also offer a money-back guarantee if unsatisfied with the first
                delivery.
              </p>
            </div>
            <div className="whyusServicePoints service5">
              <img alt="Service Image" src={whyus5}></img>
              <h5>Client-Centricity</h5>
              <p>
                We view every client as our partner and collaborate closely to
                understand your goals and needs. We find our win in your success
                and try to keep a long-lasting relationship with you.
              </p>
            </div>
            <div className="whyusServicePoints service6">
              <img alt="Service Image" src={whyus6}></img>
              <h5>Real-Time Progress Update</h5>
              <p>
                Stay updated on your project's progress in real time with our
                interactive board, giving you full visibility at every stage.
              </p>
            </div>
          </div>
        </div>
        <ContactFormShort></ContactFormShort>
      </div>
    </section>
  );
}

export default Whychooseus;
