import React from "react";
import "./navModal.css";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../../Assets/Images/KlugMobile.svg";

const NavbarModal = ({ isOpen, toggleMenu, handleScroll }) => {
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="menu-header">
        <div className="logo">
          <Link to="/" onClick={toggleMenu}>
            <img alt="logo" src={logo} />
          </Link>
        </div>
        <button className="close-btn" onClick={toggleMenu}>
          &times;
        </button>
      </div>
      <div className="menu-items">
        <NavLink to="/" onClick={toggleMenu}>
          Home
        </NavLink>
        <hr />
        <NavLink to="/about" onClick={toggleMenu}>
          About us
        </NavLink>
        <hr />
        <NavLink to="/services" onClick={toggleMenu}>
          Our Services
        </NavLink>
        <hr />
        <NavLink to="/whyUs" onClick={toggleMenu}>
          Why Us
        </NavLink>
        <hr />
      </div>
      <button
        className="contact-btn"
        onClick={() => {
          handleScroll();
          toggleMenu();
        }}
      >
        Contact Us
      </button>
      <div className="copyright">Copyright © 2024 | Klug</div>
    </div>
  );
};

export default NavbarModal;
